import React from "react";
import styles from "../styles/ComingSoon.module.css";
import { FaTools } from "react-icons/fa";

const ComingSoon = ({ feature }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FaTools className={styles.icon} />
        <h1 className={styles.title}>{feature} Coming Soon!</h1>
        <p className={styles.description}>
          We're working hard to bring you this feature. Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
