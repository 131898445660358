import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import styles from "../styles/Survey.module.css";
import PublicLayout from "./PublicLayout";

const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOX: "checkbox",
  TEXT: "text",
  RATING: "rating",
};

const SurveyForm = () => {
  const [survey, setSurvey] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const { projectId, surveyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSurvey();
  }, [projectId, surveyId]);

  useEffect(() => {
    // Add keyboard event listener
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleNext();
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => window.removeEventListener("keypress", handleKeyPress);
  }, [currentQuestionIndex, answers]);

  const fetchSurvey = async () => {
    try {
      const response = await axios.get(`/api/surveys/${surveyId}/public`);
      setSurvey(response.data);
      // Initialize answers object
      const initialAnswers = {};
      response.data.questions.forEach((q) => {
        initialAnswers[q.id] = "";
      });
      setAnswers(initialAnswers);
    } catch (err) {
      setError("Survey not found or no longer available");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (survey) {
      const answeredQuestions = Object.values(answers).filter(
        (answer) => answer !== ""
      ).length;
      const progressPercentage =
        (answeredQuestions / survey.questions.length) * 100;
      setProgress(progressPercentage);
    }
  }, [answers, survey]);

  const handleAnswerChange = (questionId, answer, type) => {
    setAnswers((prev) => {
      if (type === QUESTION_TYPES.CHECKBOX) {
        // Handle checkbox (multiple answers)
        const currentAnswers = prev[questionId] || [];
        if (currentAnswers.includes(answer)) {
          return {
            ...prev,
            [questionId]: currentAnswers.filter((a) => a !== answer),
          };
        } else {
          return {
            ...prev,
            [questionId]: [...currentAnswers, answer],
          };
        }
      } else {
        // Handle radio and text
        return {
          ...prev,
          [questionId]: answer,
        };
      }
    });
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        return (
          <div className={styles.optionsContainer}>
            {question.options.map((option, index) => (
              <label key={index} className={styles.optionLabel}>
                <input
                  type="radio"
                  name={`question_${question.id}`}
                  value={option}
                  checked={answers[question.id] === option}
                  onChange={() =>
                    handleAnswerChange(question.id, option, question.type)
                  }
                  className={styles.radioInput}
                />
                <span className={styles.optionText}>{option}</span>
              </label>
            ))}
          </div>
        );

      case QUESTION_TYPES.CHECKBOX:
        return (
          <div className={styles.optionsContainer}>
            {question.options.map((option, index) => (
              <label key={index} className={styles.optionLabel}>
                <input
                  type="checkbox"
                  value={option}
                  checked={(answers[question.id] || []).includes(option)}
                  onChange={() =>
                    handleAnswerChange(question.id, option, question.type)
                  }
                  className={styles.checkboxInput}
                />
                <span className={styles.optionText}>{option}</span>
              </label>
            ))}
          </div>
        );

      case QUESTION_TYPES.TEXT:
        return (
          <textarea
            value={answers[question.id] || ""}
            onChange={(e) =>
              handleAnswerChange(question.id, e.target.value, question.type)
            }
            className={styles.textInput}
            placeholder="Enter your answer here..."
            rows={4}
          />
        );

      case QUESTION_TYPES.RATING:
        const isSlider = question.ratingScale?.max === 10;
        return (
          <div className={styles.ratingContainer}>
            {isSlider ? (
              <div className={styles.sliderContainer}>
                <div className={styles.sliderLabels}>
                  <span>1 - Very Poor</span>
                  <span>5 - Average</span>
                  <span>10 - Excellent</span>
                </div>
                <input
                  type="range"
                  min={question.ratingScale.min}
                  max={question.ratingScale.max}
                  step="1"
                  value={answers[question.id] || question.ratingScale.min}
                  onChange={(e) =>
                    handleAnswerChange(
                      question.id,
                      e.target.value,
                      question.type
                    )
                  }
                  className={styles.slider}
                />
                <div className={styles.sliderValue}>
                  Selected Rating: {answers[question.id] || "-"}
                </div>
                <div className={styles.sliderTicks}>
                  {Array.from(
                    {
                      length:
                        question.ratingScale.max - question.ratingScale.min + 1,
                    },
                    (_, i) => i + question.ratingScale.min
                  ).map((num) => (
                    <span key={num}>{num}</span>
                  ))}
                </div>
              </div>
            ) : (
              <div className={styles.ratingScale} data-points="5">
                {Array.from(
                  {
                    length:
                      question.ratingScale.max - question.ratingScale.min + 1,
                  },
                  (_, i) => String(i + question.ratingScale.min)
                ).map((value) => (
                  <label key={value} className={styles.ratingOption}>
                    <input
                      type="radio"
                      name={`question_${question.id}`}
                      value={value}
                      checked={answers[question.id] === value}
                      onChange={() =>
                        handleAnswerChange(question.id, value, question.type)
                      }
                      className={styles.ratingInput}
                    />
                    <div className={styles.ratingContent}>
                      <span className={styles.ratingValue}>{value}</span>
                      <span className={styles.ratingLabel}>
                        {question.ratingScale.labels[value]}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const handleNext = () => {
    const currentQuestion = survey.questions[currentQuestionIndex];
    if (!answers[currentQuestion.id]) {
      return; // Don't proceed if no answer selected
    }

    if (currentQuestionIndex < survey.questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      await axios.post(`/api/surveys/${surveyId}/responses`, {
        answers: Object.entries(answers).map(([questionId, answer]) => ({
          questionId,
          answer,
        })),
      });
      navigate(`/projects/${projectId}/surveys/${surveyId}/thank-you`);
    } catch (err) {
      setError("Failed to submit survey");
    }
  };

  if (loading) return <div className={styles.loading}>Loading survey...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!survey) return <div className={styles.error}>Survey not found</div>;

  const currentQuestion = survey.questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === survey.questions.length - 1;
  const hasAnswer = answers[currentQuestion.id] !== "";

  return (
    <PublicLayout brandingImageUrl={survey?.brandingImageUrl}>
      <div className={styles.surveyFormContainer}>
        <div className={styles.progressContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${progress}%` }}
          />
          <span className={styles.progressText}>
            Question {currentQuestionIndex + 1} of {survey.questions.length}
          </span>
        </div>

        <div className={styles.questionCard}>
          <h2 className={styles.surveyTitle}>{survey.title}</h2>

          <div className={styles.questionContent}>
            <h3 className={styles.questionText}>{currentQuestion.text}</h3>

            {renderQuestion(currentQuestion)}
          </div>

          <div className={styles.navigationButtons}>
            <button
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              className={`${styles.navButton} ${styles.prevButton}`}
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={!hasAnswer}
              className={`${styles.navButton} ${styles.nextButton}`}
            >
              {isLastQuestion ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default SurveyForm;
