import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { useAuth } from "../context/AuthContext";
import { useRecaptcha } from "../hooks/useRecaptcha";
import AuthLayout from "./AuthLayout";
import styles from "../styles/Auth.module.css";

const MAX_RETRIES = 3;
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [lockoutEndTime, setLockoutEndTime] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { executeRecaptcha } = useRecaptcha();

  useEffect(() => {
    // Check for existing lockout
    const storedLockoutEnd = localStorage.getItem("adminLockoutEnd");
    if (storedLockoutEnd) {
      const lockoutTime = parseInt(storedLockoutEnd);
      if (lockoutTime > Date.now()) {
        setLockoutEndTime(lockoutTime);
      } else {
        localStorage.removeItem("adminLockoutEnd");
        localStorage.removeItem("adminRetryCount");
      }
    }

    // Check for existing retry count
    const storedRetryCount = localStorage.getItem("adminRetryCount");
    if (storedRetryCount) {
      setRetryCount(parseInt(storedRetryCount));
    }
  }, []);

  const handleChange = (e) => {
    setError("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isLocked = () => {
    return lockoutEndTime && lockoutEndTime > Date.now();
  };

  const getRemainingLockoutTime = () => {
    if (!isLocked()) return null;
    const remaining = Math.ceil((lockoutEndTime - Date.now()) / 1000 / 60);
    return remaining;
  };

  const incrementRetryCount = () => {
    const newCount = retryCount + 1;
    setRetryCount(newCount);
    localStorage.setItem("adminRetryCount", newCount);

    if (newCount >= MAX_RETRIES) {
      const lockoutEnd = Date.now() + LOCKOUT_DURATION;
      setLockoutEndTime(lockoutEnd);
      localStorage.setItem("adminLockoutEnd", lockoutEnd);
    }
  };

  const resetRetryCount = () => {
    setRetryCount(0);
    setLockoutEndTime(null);
    localStorage.removeItem("adminRetryCount");
    localStorage.removeItem("adminLockoutEnd");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLocked()) {
      setError(
        `Account locked. Try again in ${getRemainingLockoutTime()} minutes.`
      );
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const recaptchaToken = await executeRecaptcha("admin_login");

      const response = await axios.post("/api/admin/auth/login", {
        ...formData,
        recaptchaToken,
      });

      const { token, user } = response.data;

      resetRetryCount();
      login(token, "admin", user);

      setTimeout(() => {
        navigate("/admin/dashboard", { replace: true });
      }, 100);
    } catch (err) {
      console.error("Login error:", err);
      incrementRetryCount();

      if (err.response?.status === 429) {
        setError("Too many login attempts. Please try again later.");
      } else {
        setError(err.response?.data?.message || "Login failed");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout>
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Admin Login</h2>
          {isLocked() ? (
            <div className={styles.lockoutMessage}>
              Account temporarily locked. Please try again in{" "}
              {getRemainingLockoutTime()} minutes.
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  placeholder="Enter your email"
                />
              </div>
              <div className={styles.formGroup}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  placeholder="Enter your password"
                />
              </div>
              {error && <div className={styles.error}>{error}</div>}
              <div className={styles.remainingAttempts}>
                Remaining attempts: {MAX_RETRIES - retryCount}
              </div>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting || isLocked()}
              >
                {isSubmitting ? "Verifying..." : "Login"}
              </button>
            </form>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default AdminLogin;
