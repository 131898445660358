import React from "react";
import styles from "../styles/Logo.module.css";

const Logo = ({ size = "medium" }) => {
  return (
    <div className={`${styles.logo} ${styles[size]}`}>
      <img src="/logo512.png" alt="AskAjo Logo" className={styles.logoImage} />
      <div className={styles.logoText}>
        <span className={styles.ask}>Aska</span>
        <span className={styles.ajo}>Jo</span>
      </div>
    </div>
  );
};

export default Logo;
