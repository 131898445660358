import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import styles from "../styles/Dashboard.module.css";

const AdminDashboard = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      const response = await axios.get("/api/surveys");
      setSurveys(response.data);
    } catch (err) {
      setError("Failed to fetch surveys");
      console.error("Error fetching surveys:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleStateChange = async (surveyId, newState) => {
    try {
      await axios.patch(`/api/surveys/${surveyId}/state`, { state: newState });
      fetchSurveys();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update survey state");
    }
  };

  const getStateActions = (survey) => {
    if (survey.state === "deleted") {
      return (
        <button
          onClick={() => handleStateChange(survey.state, "draft")}
          className={styles.restoreButton}
        >
          Restore
        </button>
      );
    }
    return null;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.dashboard}>
      <h2>All Surveys</h2>
      <div className={styles.surveyList}>
        {surveys.length === 0 ? (
          <p>No surveys available</p>
        ) : (
          <div className={styles.grid}>
            {surveys.map((survey) => (
              <div key={survey.surveyId} className={styles.surveyCard}>
                <div className={styles.surveyHeader}>
                  <h4>{survey.title}</h4>
                  <span className={`${styles.state} ${styles[survey.state]}`}>
                    {survey.state}
                  </span>
                </div>
                <p>Created by: {survey.userId}</p>
                <p>Questions: {survey.questions.length}</p>
                <div className={styles.cardActions}>
                  {getStateActions(survey)}
                  <button
                    onClick={() =>
                      navigate(`/admin/surveys/${survey.surveyId}/results`)
                    }
                    className={styles.viewButton}
                  >
                    View Results
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
