import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../utils/axiosConfig";
import styles from "../styles/SurveyResults.module.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { CSVLink } from "react-csv";

ChartJS.register(ArcElement, Tooltip, Legend);

const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOX: "checkbox",
  TEXT: "text",
  RATING: "rating",
};

const deletedQuestionStyle = {
  opacity: 0.7,
  backgroundColor: "#f8f9fa",
  padding: "0.5rem",
  borderRadius: "4px",
  marginLeft: "1rem",
  fontSize: "0.9rem",
  color: "#dc3545",
};

const SurveyResults = () => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState("statistics"); // 'statistics' or 'table'
  const { surveyId } = useParams();

  useEffect(() => {
    fetchResults();
  }, [surveyId]);

  const fetchResults = async () => {
    try {
      const response = await axios.get(`/api/surveys/${surveyId}/results`);
      setResults(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch results");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const renderQuestionResults = (question, index) => {
    if (question.type === QUESTION_TYPES.TEXT) {
      return (
        <div className={styles.textResponses}>
          <h4>Text Responses:</h4>
          <div className={styles.responsesList}>
            {results.responses.map((response, i) => (
              <div key={i} className={styles.textResponse}>
                {response.answers[question.id] || "No answer provided"}
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (question.type === QUESTION_TYPES.RATING) {
      const totalResponses = question.statistics.reduce(
        (sum, stat) => sum + stat.count,
        0
      );
      const averageRating =
        question.statistics.reduce(
          (sum, stat) => sum + Number(stat.answer) * stat.count,
          0
        ) / totalResponses;

      return (
        <div className={styles.ratingResults}>
          <div className={styles.averageRating}>
            <h4>Average Rating: {averageRating.toFixed(1)}</h4>
            <div className={styles.ratingDistribution}>
              {question.statistics.map((stat) => (
                <div key={stat.answer} className={styles.ratingBar}>
                  <span className={styles.ratingValue}>{stat.answer}</span>
                  <div className={styles.barContainer}>
                    <div
                      className={styles.barFill}
                      style={{ width: `${stat.percentage}%` }}
                    />
                  </div>
                  <span className={styles.ratingCount}>
                    {stat.count} ({stat.percentage}%)
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    // For multiple choice and checkbox questions
    const chartData = {
      labels: question.statistics.map((stat) => stat.answer),
      datasets: [
        {
          data: question.statistics.map((stat) => stat.count),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ],
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 10,
            padding: 5,
            font: {
              size: 10,
            },
          },
        },
      },
      layout: {
        padding: {
          top: 5,
          bottom: 5,
          left: 5,
          right: 5,
        },
      },
    };

    return (
      <div className={styles.chartContainer}>
        <div className={styles.chartWrapper}>
          <Pie data={chartData} options={chartOptions} />
        </div>
        <div className={styles.statistics}>
          {question.statistics.map((stat) => (
            <div key={stat.answer} className={styles.statItem}>
              <div className={styles.statHeader}>
                <span className={styles.answer}>{stat.answer}</span>
                <span className={styles.count}>
                  ({stat.count} responses - {stat.percentage}%)
                </span>
              </div>
              <div className={styles.progressBar}>
                <div
                  className={styles.progressFill}
                  style={{ width: `${stat.percentage}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const prepareCSVData = () => {
    const headers = [
      { label: "Response ID", key: "responseId" },
      { label: "Submitted At", key: "submittedAt" },
      ...results.questions.map((q, index) => ({
        label: `Q${index + 1}: ${q.text}`,
        key: `q${q.id}`,
      })),
    ];

    const csvData = results.responses.map((response) => {
      const row = {
        responseId: response.responseId,
        submittedAt: formatDate(response.submittedAt),
      };
      results.questions.forEach((question) => {
        row[`q${question.id}`] = response.answers[question.id] || "";
      });
      return row;
    });

    return { headers, data: csvData };
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!results) return <div>No results found</div>;

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2>{results.title} - Results</h2>
        <div className={styles.viewToggle}>
          <button
            className={`${styles.toggleButton} ${
              viewMode === "statistics" ? styles.active : ""
            }`}
            onClick={() => setViewMode("statistics")}
          >
            Statistics View
          </button>
          <button
            className={`${styles.toggleButton} ${
              viewMode === "table" ? styles.active : ""
            }`}
            onClick={() => setViewMode("table")}
          >
            Table View
          </button>
        </div>
      </div>

      <div className={styles.totalResponses}>
        Total Responses: {results.totalResponses}
      </div>

      {viewMode === "statistics" ? (
        // Statistics View
        <div className={styles.resultsContainer}>
          {results.questions.map((question, index) => (
            <div key={question.id} className={styles.questionResults}>
              <h3>
                Question {index + 1}: {question.text}
                <span className={styles.questionType}>
                  (
                  {question.type === QUESTION_TYPES.TEXT
                    ? "Text Answer"
                    : question.type === QUESTION_TYPES.CHECKBOX
                    ? "Multiple Choice (Multiple Answers)"
                    : "Multiple Choice (Single Answer)"}
                  )
                </span>
                {question.isDeleted && (
                  <span style={deletedQuestionStyle}>
                    (Deleted - Only showing existing responses)
                  </span>
                )}
              </h3>
              {renderQuestionResults(question, index)}
            </div>
          ))}
        </div>
      ) : (
        // Table View
        <div className={styles.tableContainer}>
          <div className={styles.tableActions}>
            <CSVLink
              data={prepareCSVData().data}
              headers={prepareCSVData().headers}
              filename={`${results.title}_responses.csv`}
              className={styles.downloadButton}
            >
              Download as CSV
            </CSVLink>
          </div>
          <table className={styles.responsesTable}>
            <thead>
              <tr>
                <th>Response ID</th>
                <th>Submitted At</th>
                {results.questions.map((question, index) => (
                  <th key={question.id}>
                    Q{index + 1}: {question.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {results.responses.map((response) => (
                <tr key={response.responseId}>
                  <td>{response.responseId}</td>
                  <td>{formatDate(response.submittedAt)}</td>
                  {results.questions.map((question) => (
                    <td key={question.id}>{response.answers[question.id]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SurveyResults;
