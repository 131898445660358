import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { useAuth } from "../context/AuthContext";
import { useRecaptcha } from "../hooks/useRecaptcha";
import AuthLayout from "./AuthLayout";
import styles from "../styles/Auth.module.css";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();
  const { executeRecaptcha } = useRecaptcha();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const recaptchaToken = await executeRecaptcha("login");

      const response = await axios.post("/api/user/auth/login", {
        email,
        password,
        recaptchaToken,
      });

      const { token, user } = response.data;

      auth.login(token, "user", user);

      setTimeout(() => {
        navigate("/dashboard", { replace: true });
      }, 100);
    } catch (error) {
      const message = error.response?.data?.message || "Login failed";
      setError(message);

      if (message.includes("verify your email")) {
        setError(
          "Please check your email to verify your account before logging in."
        );
      } else if (message.includes("Account locked")) {
        setError(
          "Account is temporarily locked due to multiple failed attempts. Please try again later."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>User Login</h2>
          {error && <div className="error-message">{error}</div>}
          {isLoading && <div className="loading-spinner">Loading...</div>}
          <form onSubmit={handleLogin} className={styles.authForm}>
            <div className={styles.formGroup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.input}
              />
            </div>
            <button type="submit" className={styles.submitButton}>
              Login
            </button>
          </form>
          <p className={styles.authLinks}>
            Don't have an account?{" "}
            <Link to="/register" className={styles.link}>
              Register here
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default UserLogin;
