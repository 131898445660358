import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "../utils/axiosConfig";
import styles from "../styles/Survey.module.css";

const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOX: "checkbox",
  TEXT: "text",
  RATING: "rating",
};

const RATING_SCALES = {
  FIVE_POINT: {
    min: 1,
    max: 5,
    labels: {
      1: "Very Poor",
      2: "Poor",
      3: "Average",
      4: "Good",
      5: "Excellent",
    },
  },
  TEN_POINT: {
    min: 1,
    max: 10,
    labels: {
      1: "Extremely Poor",
      5: "Average",
      10: "Excellent",
    },
  },
};

const CreateSurvey = () => {
  const { surveyId } = useParams();
  const [title, setTitle] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [questions, setQuestions] = useState([
    {
      id: uuidv4(),
      text: "",
      type: QUESTION_TYPES.MULTIPLE_CHOICE,
      options: [""],
    },
  ]);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const isEditMode = Boolean(surveyId);
  const [brandingImageUrl, setBrandingImageUrl] = useState("");
  const [brandingImageError, setBrandingImageError] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      fetchSurveyData();
    }
  }, [surveyId]);

  const fetchSurveyData = async () => {
    try {
      const response = await axios.get(`/api/surveys/${surveyId}`);
      const survey = response.data;

      setTitle(survey?.title || "");
      setProjectName(survey?.projectName || "");
      setProjectId(survey?.projectId || "");
      setBrandingImageUrl(survey?.brandingImageUrl || "");

      if (survey?.questions && Array.isArray(survey.questions)) {
        setQuestions(
          survey.questions.map((q) => ({
            id: q.id || uuidv4(),
            text: q.text || "",
            type: q.type || QUESTION_TYPES.MULTIPLE_CHOICE,
            options: Array.isArray(q.options) ? q.options : [""],
            ratingScale: q.ratingScale || RATING_SCALES.FIVE_POINT,
          }))
        );
      }
    } catch (err) {
      setError("Failed to fetch survey data");
      console.error("Error fetching survey:", err);
    }
  };

  useEffect(() => {
    if (projectName) {
      setProjectId(projectName.toLowerCase().replace(/\s+/g, "-"));
    }
  }, [projectName]);

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].text = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: uuidv4(),
        text: "",
        type: QUESTION_TYPES.MULTIPLE_CHOICE,
        options: [""],
      },
    ]);
  };

  const removeQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push("");
    setQuestions(newQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options = newQuestions[
      questionIndex
    ].options.filter((_, i) => i !== optionIndex);
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index, type) => {
    const newQuestions = [...questions];
    newQuestions[index].type = type;
    if (type === QUESTION_TYPES.TEXT) {
      newQuestions[index].options = [];
    } else if (type === QUESTION_TYPES.RATING) {
      newQuestions[index].ratingScale = RATING_SCALES.FIVE_POINT;
      newQuestions[index].options = Array.from(
        {
          length:
            RATING_SCALES.FIVE_POINT.max - RATING_SCALES.FIVE_POINT.min + 1,
        },
        (_, i) => String(i + RATING_SCALES.FIVE_POINT.min)
      );
    } else if (!newQuestions[index].options?.length) {
      newQuestions[index].options = [""];
    }
    setQuestions(newQuestions);
  };

  const validateImageUrl = async (url) => {
    try {
      const response = await axios.head(url);
      const contentType = response.headers["content-type"];
      if (!contentType.startsWith("image/")) {
        throw new Error("URL must point to a valid image file");
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess("");

    const trimmedProjectName = projectName?.trim() || "";
    const trimmedTitle = title?.trim() || "";

    if (!trimmedProjectName) {
      setError("Project name is required");
      setIsSubmitting(false);
      return;
    }

    if (!trimmedTitle) {
      setError("Survey title is required");
      setIsSubmitting(false);
      return;
    }

    const validQuestions = questions.filter((q) => q && typeof q === "object");
    if (!validQuestions.length) {
      setError("At least one question is required");
      setIsSubmitting(false);
      return;
    }

    if (brandingImageUrl && !(await validateImageUrl(brandingImageUrl))) {
      setError("Please provide a valid image URL");
      setIsSubmitting(false);
      return;
    }

    const surveyData = {
      title: trimmedTitle,
      projectName: trimmedProjectName,
      projectId:
        projectId || trimmedProjectName.toLowerCase().replace(/\s+/g, "-"),
      brandingImageUrl: brandingImageUrl || "",
      questions: validQuestions.map((q) => ({
        id: q.id || uuidv4(),
        text: q.text || "",
        type: q.type || QUESTION_TYPES.MULTIPLE_CHOICE,
        options:
          q.type !== QUESTION_TYPES.TEXT
            ? (q.options || []).filter((opt) => opt && opt.trim() !== "")
            : [],
        ratingScale:
          q.type === QUESTION_TYPES.RATING ? q.ratingScale : undefined,
      })),
    };

    try {
      if (isEditMode) {
        await axios.put(`/api/surveys/${surveyId}`, surveyData);
        setSuccess("Survey updated successfully");
      } else {
        const response = await axios.post("/api/surveys", surveyData);
        setSuccess("Survey created successfully and saved as draft");
      }

      setIsRedirecting(true);
      setTimeout(() => {
        navigate(`/dashboard`);
      }, 1500);
    } catch (err) {
      console.error("Error submitting survey:", err);
      setError(
        err.response?.data?.message ||
          `Failed to ${isEditMode ? "update" : "create"} survey`
      );
      setIsRedirecting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2>{isEditMode ? "Edit Survey" : "Create New Survey"}</h2>
      {success && (
        <div className={styles.successOverlay}>
          <div className={styles.successMessage}>
            <div className={styles.successIcon}>✓</div>
            <p>{success}</p>
            <p className={styles.redirectingText}>
              Redirecting to dashboard...
            </p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label>Project Name *</label>
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
            className={styles.input}
            placeholder="Enter project name"
          />
        </div>
        <div className={styles.formGroup}>
          <label>Branding Image URL (optional)</label>
          <input
            type="url"
            value={brandingImageUrl}
            onChange={async (e) => {
              const url = e.target.value;
              setBrandingImageUrl(url);
              setBrandingImageError("");

              if (url) {
                const isValid = await validateImageUrl(url);
                if (!isValid) {
                  setBrandingImageError("Please provide a valid image URL");
                }
              }
            }}
            className={`${styles.input} ${
              brandingImageError ? styles.hasError : ""
            }`}
            placeholder="https://example.com/logo.png"
          />
          {brandingImageError && (
            <div className={styles.error}>{brandingImageError}</div>
          )}
          {brandingImageUrl && !brandingImageError && (
            <div className={styles.imagePreview}>
              <img
                src={brandingImageUrl}
                alt="Branding Preview"
                className={styles.previewImage}
                onError={() => setBrandingImageError("Failed to load image")}
              />
              <span className={styles.previewText}>
                This is how your branding will appear in the survey
              </span>
            </div>
          )}
        </div>
        <div className={styles.formGroup}>
          <label>Survey Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className={styles.input}
          />
        </div>

        {questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionBlock}>
            <div className={styles.questionHeader}>
              <h3>Question {qIndex + 1}</h3>
              {questions.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeQuestion(qIndex)}
                  className={styles.removeButton}
                >
                  Remove Question
                </button>
              )}
            </div>

            <div className={styles.formGroup}>
              <label>Question Text</label>
              <input
                type="text"
                value={question.text}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                required
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Question Type</label>
              <select
                value={question.type}
                onChange={(e) =>
                  handleQuestionTypeChange(qIndex, e.target.value)
                }
                className={styles.select}
              >
                <option value={QUESTION_TYPES.MULTIPLE_CHOICE}>
                  Multiple Choice (Single Answer)
                </option>
                <option value={QUESTION_TYPES.CHECKBOX}>
                  Multiple Choice (Multiple Answers)
                </option>
                <option value={QUESTION_TYPES.TEXT}>Text Answer</option>
                <option value={QUESTION_TYPES.RATING}>Rating Scale</option>
              </select>
            </div>

            {question.type !== QUESTION_TYPES.TEXT &&
              question.type !== QUESTION_TYPES.RATING && (
                <div className={styles.optionsContainer}>
                  <label>Options</label>
                  {question.options.map((option, oIndex) => (
                    <div key={oIndex} className={styles.optionGroup}>
                      <input
                        type="text"
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(qIndex, oIndex, e.target.value)
                        }
                        required
                        className={styles.input}
                        placeholder={`Option ${oIndex + 1}`}
                      />
                      {question.options.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeOption(qIndex, oIndex)}
                          className={styles.removeOptionButton}
                        >
                          ×
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => addOption(qIndex)}
                    className={styles.addButton}
                  >
                    Add Option
                  </button>
                </div>
              )}

            {question.type === QUESTION_TYPES.RATING && (
              <div className={styles.ratingConfig}>
                <label>Rating Scale</label>
                <select
                  value={question.ratingScale?.max === 10 ? "10" : "5"}
                  onChange={(e) => {
                    const newQuestions = [...questions];
                    const scale =
                      e.target.value === "10"
                        ? RATING_SCALES.TEN_POINT
                        : RATING_SCALES.FIVE_POINT;
                    newQuestions[qIndex].ratingScale = scale;
                    newQuestions[qIndex].options = Array.from(
                      { length: scale.max - scale.min + 1 },
                      (_, i) => String(i + scale.min)
                    );
                    setQuestions(newQuestions);
                  }}
                  className={styles.select}
                >
                  <option value="5">5-Point Scale</option>
                  <option value="10">10-Point Scale (Slider)</option>
                </select>
                <div className={styles.ratingPreview}>
                  {question.ratingScale?.max === 10 ? (
                    <div className={styles.sliderPreview}>
                      <div className={styles.sliderLabels}>
                        <span>1 - Very Poor</span>
                        <span>5 - Average</span>
                        <span>10 - Excellent</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="10"
                        value="5"
                        className={styles.slider}
                        readOnly
                      />
                      <div className={styles.sliderTicks}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                          <span key={num}>{num}</span>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.ratingScalePreview}>
                      {question.options.map((value) => (
                        <div key={value} className={styles.ratingValuePreview}>
                          <span className={styles.ratingNumber}>{value}</span>
                          <span className={styles.ratingLabel}>
                            {question.ratingScale.labels[value]}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}

        <div className={styles.buttonGroup}>
          <button
            type="button"
            onClick={addQuestion}
            className={styles.addButton}
            disabled={isSubmitting || isRedirecting}
          >
            Add Question
          </button>
          <button
            type="submit"
            disabled={isSubmitting || isRedirecting}
            className={styles.submitButton}
          >
            {isSubmitting
              ? isEditMode
                ? "Updating..."
                : "Creating..."
              : isRedirecting
              ? "Redirecting..."
              : isEditMode
              ? "Update Survey"
              : "Create Survey"}
          </button>
        </div>

        {error && <div className={styles.error}>{error}</div>}
      </form>
    </div>
  );
};

export default CreateSurvey;
