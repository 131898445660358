import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import ThemeProvider from "./context/ThemeProvider";
import AdminLogin from "./components/AdminLogin";
import UserLogin from "./components/UserLogin";
import UserRegister from "./components/UserRegister";
import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/AdminDashboard";
import CreateSurvey from "./components/CreateSurvey";
import SurveyResults from "./components/SurveyResults";
import NotFound from "./components/NotFound";
import Layout from "./components/Layout";
import SurveyForm from "./components/SurveyForm";
import ThankYou from "./components/ThankYou";
import Profile from "./components/Profile";
import EmailVerification from "./components/EmailVerification";
import ComingSoon from "./components/ComingSoon";

function App() {
  const { isAuthenticated, userType, loading } = useAuth();

  // Protected Route Components
  const ProtectedRoute = ({ children, allowedRole }) => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (!isAuthenticated || (allowedRole && userType !== allowedRole)) {
      return <Navigate to={userType === "admin" ? "/admin/login" : "/login"} />;
    }

    return children;
  };

  const AdminRoute = ({ children }) => (
    <ProtectedRoute allowedRole="admin">{children}</ProtectedRoute>
  );

  const UserRoute = ({ children }) => (
    <ProtectedRoute allowedRole="user">{children}</ProtectedRoute>
  );

  // Public Route Component
  const PublicRoute = ({ children }) => {
    if (isAuthenticated) {
      return (
        <Navigate
          to={userType === "admin" ? "/admin/dashboard" : "/dashboard"}
        />
      );
    }

    return children;
  };

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/admin/login"
            element={
              <PublicRoute>
                <AdminLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <UserLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <UserRegister />
              </PublicRoute>
            }
          />

          {/* Email Verification Route */}
          <Route path="/verify/:token" element={<EmailVerification />} />

          {/* Protected Admin Routes */}
          <Route
            path="/admin/*"
            element={
              <AdminRoute>
                <Layout>
                  <Routes>
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="profile" element={<Profile />} />
                    <Route
                      path="surveys"
                      element={<ComingSoon feature="Surveys Overview" />}
                    />
                    <Route
                      path="respondents"
                      element={<ComingSoon feature="Respondents Management" />}
                    />
                    <Route
                      path="analytics"
                      element={<ComingSoon feature="Analytics Dashboard" />}
                    />
                    <Route
                      path="surveys/:surveyId/results"
                      element={<SurveyResults />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Layout>
              </AdminRoute>
            }
          />

          {/* Protected User Routes */}
          <Route
            path="/*"
            element={
              <UserRoute>
                <Layout>
                  <Routes>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="surveys/create" element={<CreateSurvey />} />
                    <Route
                      path="surveys"
                      element={<ComingSoon feature="Surveys Overview" />}
                    />
                    <Route
                      path="respondents"
                      element={<ComingSoon feature="Respondents Management" />}
                    />
                    <Route
                      path="analytics"
                      element={<ComingSoon feature="Analytics Dashboard" />}
                    />
                    <Route
                      path="surveys/:surveyId/results"
                      element={<SurveyResults />}
                    />
                    <Route
                      path="surveys/:surveyId/edit"
                      element={<CreateSurvey />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Layout>
              </UserRoute>
            }
          />

          {/* Public Survey Routes */}
          <Route
            path="/projects/:projectId/surveys/:surveyId"
            element={<SurveyForm />}
          />
          <Route
            path="/projects/:projectId/surveys/:surveyId/thank-you"
            element={<ThankYou />}
          />

          {/* Root Redirect */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
