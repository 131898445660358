import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import PublicLayout from "./PublicLayout";
import styles from "../styles/ThankYou.module.css";

function ThankYou() {
  const navigate = useNavigate();

  return (
    <PublicLayout>
      <div className={styles.thankYouContainer}>
        <div className={styles.card}>
          <div className={styles.icon}>
            <FaCheckCircle />
          </div>
          <h1 className={styles.title}>Thank You!</h1>
          <p className={styles.message}>
            Your response has been successfully recorded. We appreciate your
            time and feedback.
          </p>

          <div className={styles.ctaSection}>
            <h2 className={styles.ctaTitle}>Create Your Own Surveys</h2>
            <p className={styles.ctaText}>
              Want to gather insights from your audience? Create professional
              surveys with AskAjo's easy-to-use platform.
            </p>
            <div className={styles.ctaFeatures}>
              <div className={styles.feature}>
                <span className={styles.featureIcon}>✨</span>
                <span>AI-Powered Survey Generation</span>
              </div>
              <div className={styles.feature}>
                <span className={styles.featureIcon}>📊</span>
                <span>Real-time Analytics</span>
              </div>
              <div className={styles.feature}>
                <span className={styles.featureIcon}>🎯</span>
                <span>Multiple Question Types</span>
              </div>
            </div>
            <button
              onClick={() => navigate("/register")}
              className={styles.ctaButton}
            >
              Get Started Free
              <FaArrowRight className={styles.arrowIcon} />
            </button>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default ThankYou;
