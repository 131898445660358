import React from "react";
import Logo from "./Logo";
import styles from "../styles/PublicLayout.module.css";

const PublicLayout = ({ children, brandingImageUrl }) => {
  return (
    <div className={styles.publicLayoutContainer}>
      <header className={styles.publicHeader}>
        <div className={styles.logoContainer}>
          <Logo size="small" />
          <span className={styles.poweredBy}>Powered by AskAjo</span>
          {brandingImageUrl && (
            <div className={styles.brandingContainer}>
              <img
                src={brandingImageUrl}
                alt="Survey Branding"
                className={styles.brandingImage}
                onError={(e) => {
                  e.target.style.display = "none";
                  console.error("Failed to load branding image");
                }}
              />
            </div>
          )}
        </div>
      </header>
      <main className={styles.publicMain}>{children}</main>
      <footer className={styles.publicFooter}>
        <p>Create your own surveys with AskAjo</p>
      </footer>
    </div>
  );
};

export default PublicLayout;
