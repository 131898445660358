import React from "react";
import theme from "../styles/theme";

// Convert theme object to CSS variables
const createCSSVariables = (obj, prefix = "") => {
  let cssVars = {};
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      cssVars = {
        ...cssVars,
        ...createCSSVariables(obj[key], `${prefix}${key}-`),
      };
    } else {
      cssVars[`--${prefix}${key}`] = obj[key];
    }
  }
  return cssVars;
};

export const ThemeProvider = ({ children }) => {
  const cssVariables = createCSSVariables(theme);

  return <div style={cssVariables}>{children}</div>;
};

export default ThemeProvider;
