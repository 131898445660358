import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import styles from "../styles/EmailVerification.module.css";
import { useRecaptcha } from "../hooks/useRecaptcha";
import AuthLayout from "./AuthLayout";

const EmailVerification = () => {
  const [status, setStatus] = useState("verifying");
  const { token } = useParams();
  const navigate = useNavigate();
  const { executeRecaptcha, isLoaded } = useRecaptcha();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!isLoaded) {
          console.log("Waiting for reCAPTCHA to load...");
          return;
        }

        const recaptchaToken = await executeRecaptcha("email_verification");

        if (!recaptchaToken) {
          console.log("No reCAPTCHA token generated, retrying...");
          return;
        }

        const response = await axios.get(
          `/api/user/auth/verify-email/${token}`,
          {
            headers: {
              "x-recaptcha-token": recaptchaToken,
            },
          }
        );

        if (response.data.alreadyVerified) {
          console.log("Email was already verified");
        }

        setStatus("success");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (error) {
        console.error("Verification error:", error);
        setStatus("error");
      }
    };

    verifyEmail();
  }, [token, navigate, executeRecaptcha, isLoaded]);

  return (
    <AuthLayout>
      <div className={styles.verificationContainer}>
        {status === "verifying" && (
          <div className={styles.verifyingState}>
            <div className={styles.spinner}></div>
            <p>Verifying your email...</p>
          </div>
        )}
        {status === "success" && (
          <div className={styles.successState}>
            <div className={styles.successIcon}>✓</div>
            <h2>Email Verified Successfully!</h2>
            <p>Redirecting to login page...</p>
          </div>
        )}
        {status === "error" && (
          <div className={styles.errorState}>
            <div className={styles.errorIcon}>✗</div>
            <h2>Verification Failed</h2>
            <p>The link may be expired or invalid.</p>
            <button
              onClick={() => navigate("/login")}
              className={styles.loginButton}
            >
              Go to Login
            </button>
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default EmailVerification;
