import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import styles from "../styles/Layout.module.css";
import {
  FaChartBar,
  FaUsers,
  FaCog,
  FaPoll,
  FaChartPie,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { RiSurveyLine } from "react-icons/ri";
import Logo from "./Logo";

const Layout = ({ children }) => {
  const { logout, userType, userData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate(userType === "admin" ? "/admin/login" : "/login");
  };

  const handleNavigation = (path) => {
    localStorage.removeItem("errorMessage");
    navigate(path);
    setIsSidebarOpen(false); // Close sidebar after navigation on mobile
  };

  const basePath = userType === "admin" ? "/admin" : "";

  const isActive = (path) => {
    return location.pathname === `${basePath}${path}`;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={styles.container}>
      <header className={styles.topBar}>
        <div className={styles.logo}>
          <Logo size="medium" />
        </div>
        <div className={styles.topBarRight}>
          <button
            className={styles.hamburger}
            onClick={toggleSidebar}
            aria-label="Toggle menu"
          >
            {isSidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
          <div className={styles.userActions}>
            <span className={styles.userName}>{userData?.name || "User"}</span>
            <button onClick={handleLogout} className={styles.logoutBtn}>
              Logout
            </button>
          </div>
        </div>
      </header>

      <div className={styles.mainContent}>
        {isSidebarOpen && (
          <div className={styles.sidebarOverlay} onClick={toggleSidebar}></div>
        )}
        <aside
          className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ""}`}
        >
          <nav className={styles.navigation}>
            <div
              className={`${styles.navItem} ${
                isActive("/dashboard") ? styles.active : ""
              }`}
              onClick={() => handleNavigation(`${basePath}/dashboard`)}
            >
              <FaChartBar className={styles.navIcon} />
              <span>Dashboard</span>
            </div>
            <div
              className={`${styles.navItem} ${
                isActive("/surveys") ? styles.active : ""
              }`}
              onClick={() => handleNavigation(`${basePath}/surveys`)}
            >
              <FaPoll className={styles.navIcon} />
              <span>Surveys</span>
            </div>
            <div
              className={`${styles.navItem} ${
                isActive("/respondents") ? styles.active : ""
              }`}
              onClick={() => handleNavigation(`${basePath}/respondents`)}
            >
              <FaUsers className={styles.navIcon} />
              <span>Respondents</span>
            </div>
            <div
              className={`${styles.navItem} ${
                isActive("/analytics") ? styles.active : ""
              }`}
              onClick={() => handleNavigation(`${basePath}/analytics`)}
            >
              <FaChartPie className={styles.navIcon} />
              <span>Analytics</span>
            </div>
            <div
              className={`${styles.navItem} ${
                isActive("/settings") ? styles.active : ""
              }`}
              onClick={() => handleNavigation(`${basePath}/profile`)}
            >
              <FaCog className={styles.navIcon} />
              <span>Settings</span>
            </div>
          </nav>
        </aside>
        <main
          className={`${styles.main} ${isSidebarOpen ? styles.mainShift : ""}`}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
