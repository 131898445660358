import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "../utils/axiosConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          handleLogout();
          return;
        }

        const storedUserType = localStorage.getItem("userType");
        const storedUserData = localStorage.getItem("userData");

        if (storedUserType && storedUserData) {
          try {
            const parsedUserData = JSON.parse(storedUserData);
            // Verify token with the appropriate endpoint
            const endpoint =
              storedUserType === "admin"
                ? "/api/admin/auth/verify-admin-token"
                : "/api/user/auth/verify-user-token";

            const response = await axios.get(endpoint);

            if (response.status === 200) {
              setIsAuthenticated(true);
              setUserType(storedUserType);
              setUserData(parsedUserData);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error("Auth check failed:", error);
            handleLogout();
          }
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  const handleLogin = (token, role, user) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userType", role);
    localStorage.setItem("userData", JSON.stringify({ ...user, role }));

    setIsAuthenticated(true);
    setUserType(role);
    setUserData({ ...user, role });
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    localStorage.removeItem("userData");

    setIsAuthenticated(false);
    setUserType(null);
    setUserData(null);
    setLoading(false);
  };

  const updateUserData = (data) => {
    const updatedData = { ...userData, ...data };
    localStorage.setItem("userData", JSON.stringify(updatedData));
    setUserData(updatedData);
  };

  const value = {
    isAuthenticated,
    userType,
    userData,
    loading,
    login: handleLogin,
    logout: handleLogout,
    updateUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
