export const SURVEY_LIMITS = {
  MAX_ACTIVE_SURVEYS: 2,
};

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOX: "checkbox",
  TEXT: "text",
  RATING: "rating",
};

export const RATING_SCALES = {
  FIVE_POINT: {
    min: 1,
    max: 5,
    labels: {
      1: "Very Poor",
      2: "Poor",
      3: "Average",
      4: "Good",
      5: "Excellent",
    },
  },
  TEN_POINT: {
    min: 1,
    max: 10,
    labels: {
      1: "Extremely Poor",
      5: "Average",
      10: "Excellent",
    },
  },
};
