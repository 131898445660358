import React from "react";
import Logo from "./Logo";
import styles from "../styles/AuthLayout.module.css";

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.authLayoutContainer}>
      <header className={styles.authHeader}>
        <div className={styles.logoContainer}>
          <Logo size="medium" />
        </div>
      </header>
      <main className={styles.authMain}>{children}</main>
      <footer className={styles.authFooter}>
        <p>© {new Date().getFullYear()} AskAjo. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AuthLayout;
