import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { useRecaptcha } from "../hooks/useRecaptcha";
import AuthLayout from "./AuthLayout";
import styles from "../styles/Auth.module.css";

const UserRegister = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: true,
    hasUpperCase: true,
    hasLowerCase: true,
    hasNumber: true,
    hasSpecialChar: true,
  });
  const navigate = useNavigate();
  const { executeRecaptcha } = useRecaptcha();

  const validatePassword = (password) => {
    return {
      minLength: password.length < 8,
      hasUpperCase: !/[A-Z]/.test(password),
      hasLowerCase: !/[a-z]/.test(password),
      hasNumber: !/\d/.test(password),
      hasSpecialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError("");

    // Handle password validation
    if (name === "password") {
      if (!passwordTouched) {
        setPasswordTouched(true);
      }
      setPasswordErrors(validatePassword(value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    // Check if any password requirements are not met
    const hasErrors = Object.values(passwordErrors).some((error) => error);
    if (hasErrors) {
      setError("Password does not meet all requirements");
      setIsSubmitting(false);
      return;
    }

    // Password match check
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setIsSubmitting(false);
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha("register");
      const response = await axios.post("/api/user/auth/register", {
        ...formData,
        recaptchaToken,
      });
      setEmailSent(true);
    } catch (error) {
      const message = error.response?.data?.message || "Registration failed";
      setError(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (emailSent) {
    return (
      <AuthLayout>
        <div className={styles.authContainer}>
          <div className={styles.authCard}>
            <h2>Registration Successful</h2>
            <div className={styles.emailSent}>
              <p>
                Please check your email to verify your account. A verification
                link has been sent to {formData.email}
              </p>
              <button
                onClick={() => navigate("/login")}
                className={styles.submitButton}
              >
                Go to Login
              </button>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }

  const getPasswordRequirements = () => {
    if (!passwordTouched) return null;

    const requirements = [
      { key: "minLength", text: "At least 8 characters" },
      { key: "hasUpperCase", text: "At least one uppercase letter" },
      { key: "hasLowerCase", text: "At least one lowercase letter" },
      { key: "hasNumber", text: "At least one number" },
      { key: "hasSpecialChar", text: "At least one special character" },
    ];

    const activeRequirements = requirements.filter(
      (req) => passwordErrors[req.key]
    );

    if (activeRequirements.length === 0) return null;

    return (
      <ul className={styles.passwordRequirements}>
        {activeRequirements.map((req) => (
          <li key={req.key} className={styles.passwordError}>
            {req.text}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <AuthLayout>
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Create Account</h2>
          <form onSubmit={handleSubmit} className={styles.authForm}>
            <div className={styles.formGroup}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className={styles.input}
                placeholder="Enter your name"
              />
            </div>

            <div className={styles.formGroup}>
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className={styles.input}
                placeholder="Enter your email"
              />
            </div>

            <div className={styles.formGroup}>
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onFocus={() => setPasswordTouched(true)}
                required
                className={styles.input}
                placeholder="Create a password"
              />
              {getPasswordRequirements()}
            </div>

            <div className={styles.formGroup}>
              <label>Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                className={styles.input}
                placeholder="Confirm your password"
              />
            </div>

            {error && <div className={styles.error}>{error}</div>}

            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Creating Account..." : "Create Account"}
            </button>
          </form>

          <p className={styles.authLinks}>
            Already have an account?{" "}
            <Link to="/login" className={styles.link}>
              Login here
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default UserRegister;
