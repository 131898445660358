import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import axios from "../utils/axiosConfig";
import styles from "../styles/Profile.module.css";
import { useRecaptcha } from "../hooks/useRecaptcha";

const Profile = () => {
  const { userData, userType, updateUserData } = useAuth();
  const [activeTab, setActiveTab] = useState("profile");
  const [formData, setFormData] = useState({
    name: userData?.name || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const { executeRecaptcha } = useRecaptcha();

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*)"
      );
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError("");
    setSuccess("");

    // Validate new password as user types
    if (name === "newPassword") {
      setPasswordErrors(validatePassword(value));
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess("");

    if (formData.name === userData.name) {
      setSuccess("No changes to update");
      setIsSubmitting(false);
      return;
    }

    try {
      const endpoint =
        userType === "admin"
          ? "/api/admin/auth/update-profile"
          : "/api/user/auth/update-profile";

      const recaptchaToken = await executeRecaptcha("profile_update");

      const response = await axios.put(endpoint, {
        name: formData.name,
        recaptchaToken,
      });

      updateUserData(response.data.user);
      setSuccess("Profile updated successfully");
    } catch (err) {
      console.error("Error updating profile:", err);
      setError(err.response?.data?.message || "Failed to update profile");
    } finally {
      setIsSubmitting(false);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Validate new password
    const errors = validatePassword(formData.newPassword);
    if (errors.length > 0) {
      setPasswordErrors(errors);
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    setIsSubmitting(true);

    try {
      const endpoint =
        userType === "admin"
          ? "/api/admin/auth/change-password"
          : "/api/user/auth/change-password";

      await axios.put(endpoint, {
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      });

      setSuccess("Password updated successfully");
      setFormData({
        ...formData,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setPasswordErrors([]);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update password");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.profileContainer}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "profile" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("profile")}
        >
          Profile Details
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "password" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("password")}
        >
          Change Password
        </button>
      </div>

      {error && <div className={styles.error}>{error}</div>}
      {success && <div className={styles.success}>{success}</div>}

      <div className={styles.formContainer}>
        {activeTab === "profile" ? (
          <form onSubmit={updateProfile} className={styles.form}>
            <div className={styles.formGroup}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Email</label>
              <input
                type="email"
                value={userData?.email || ""}
                disabled
                className={styles.disabledInput}
              />
              <small className={styles.helperText}>
                Email cannot be changed
              </small>
            </div>
            <button
              type="submit"
              disabled={isSubmitting || formData.name === userData?.name}
              className={styles.submitButton}
            >
              {isSubmitting ? "Updating..." : "Update Profile"}
            </button>
          </form>
        ) : (
          <form onSubmit={updatePassword} className={styles.form}>
            <div className={styles.formGroup}>
              <label>Current Password</label>
              <input
                type="password"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>New Password</label>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                required
              />
              {passwordErrors.length > 0 && (
                <ul className={styles.passwordRequirements}>
                  {passwordErrors.map((error, index) => (
                    <li key={index} className={styles.passwordError}>
                      {error}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className={styles.formGroup}>
              <label>Confirm New Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting || passwordErrors.length > 0}
              className={styles.submitButton}
            >
              {isSubmitting ? "Updating..." : "Change Password"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Profile;
