import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import styles from "../styles/Dashboard.module.css";
import { SURVEY_LIMITS } from "../utils/constants";

const Dashboard = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSurveysCount, setActiveSurveysCount] = useState(0);
  const [expandedSections, setExpandedSections] = useState({
    active: true,
    drafts: true,
    paused: true,
    others: true,
  });
  const [expandedCards, setExpandedCards] = useState({});
  const [aiPrompt, setAiPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveys();
  }, []);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const fetchSurveys = async () => {
    try {
      const response = await axios.get("/api/surveys");
      setSurveys(response.data);
      const activeCount = response.data.filter(
        (survey) => survey.state === "active"
      ).length;
      setActiveSurveysCount(activeCount);
      setError(null);
    } catch (err) {
      setError("Failed to fetch surveys");
      console.error("Error fetching surveys:", err);
    } finally {
      setLoading(false);
    }
  };

  // Group surveys by state
  const groupedSurveys = surveys.reduce(
    (acc, survey) => {
      if (survey.state === "active") {
        acc.active.push(survey);
      } else if (survey.state === "draft") {
        acc.drafts.push(survey);
      } else if (survey.state === "paused") {
        acc.paused.push(survey);
      } else if (survey.state !== "deleted") {
        acc.others.push(survey);
      }
      return acc;
    },
    { active: [], drafts: [], paused: [], others: [] }
  );

  const createNewSurvey = () => {
    navigate("/surveys/create");
  };

  const viewResults = (surveyId) => {
    navigate(`/surveys/${surveyId}/results`);
  };

  const getSurveyLink = (survey) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/projects/${survey.projectId}/surveys/${survey.surveyId}`;
  };

  const handleStateChange = async (surveyId, newState) => {
    try {
      await axios.patch(`/api/surveys/${surveyId}/state`, { state: newState });
      await fetchSurveys();
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update survey state");
      console.error("Error updating survey state:", err);
    }
  };

  const dismissError = () => {
    setError(null);
  };

  const canLaunchSurvey = (currentSurvey) => {
    if (currentSurvey.state !== "draft") return false;
    return activeSurveysCount < SURVEY_LIMITS.MAX_ACTIVE_SURVEYS;
  };

  const getStateActions = (survey) => {
    switch (survey.state) {
      case "draft":
        return (
          <>
            <button
              onClick={() => handleStateChange(survey.surveyId, "active")}
              className={`${styles.launchButton} ${
                !canLaunchSurvey(survey) ? styles.disabled : ""
              }`}
              disabled={!canLaunchSurvey(survey)}
              title={
                !canLaunchSurvey(survey)
                  ? "Maximum active surveys limit reached"
                  : ""
              }
            >
              Launch
            </button>
            <button
              onClick={() => handleStateChange(survey.surveyId, "deleted")}
              className={styles.deleteButton}
            >
              Delete
            </button>
          </>
        );
      case "active":
        return (
          <>
            <button
              onClick={() => handleStateChange(survey.surveyId, "paused")}
              className={styles.pauseButton}
            >
              Pause
            </button>
            <button
              onClick={() => handleStateChange(survey.surveyId, "closed")}
              className={styles.closeButton}
            >
              Close
            </button>
          </>
        );
      case "paused":
        return (
          <>
            <button
              onClick={() => handleStateChange(survey.surveyId, "active")}
              className={styles.resumeButton}
            >
              Resume
            </button>
            <button
              onClick={() => handleStateChange(survey.surveyId, "closed")}
              className={styles.closeButton}
            >
              Close
            </button>
          </>
        );
      case "closed":
        return (
          <>
            <span className={styles.closedStatus}>Survey Closed</span>
            <button
              onClick={() => handleStateChange(survey.surveyId, "deleted")}
              className={styles.deleteButton}
            >
              Delete
            </button>
          </>
        );
      default:
        return null;
    }
  };

  // Toggle section expansion
  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  // Toggle card expansion
  const toggleCard = (surveyId) => {
    setExpandedCards((prev) => ({
      ...prev,
      [surveyId]: !prev[surveyId],
    }));
  };

  const renderSurveySection = (
    title,
    surveys,
    sectionKey,
    showEmptyMessage = true
  ) => {
    if (surveys.length === 0 && !showEmptyMessage) return null;

    return (
      <div className={styles.surveySection}>
        <div
          className={styles.sectionHeader}
          onClick={() => toggleSection(sectionKey)}
          role="button"
          tabIndex={0}
        >
          <h3 className={styles.sectionTitle}>
            {title} ({surveys.length})
            <span
              className={`${styles.sectionArrow} ${
                expandedSections[sectionKey] ? styles.expanded : ""
              }`}
            >
              ▼
            </span>
          </h3>
        </div>

        <div
          className={`${styles.sectionContent} ${
            expandedSections[sectionKey] ? styles.expanded : ""
          }`}
        >
          {surveys.length === 0 ? (
            <p className={styles.emptyMessage}>No surveys in this section</p>
          ) : (
            <div className={styles.grid}>
              {surveys.map((survey) => (
                <div
                  key={survey.surveyId}
                  className={`${styles.surveyCard} ${
                    expandedCards[survey.surveyId] ? styles.expanded : ""
                  }`}
                  onClick={() => toggleCard(survey.surveyId)}
                >
                  <div className={styles.surveyCardPreview}>
                    <h4 className={styles.surveyTitle}>{survey.title}</h4>
                    <p className={styles.surveyCount}>
                      {survey.questions?.length || 0} Questions
                    </p>
                    <span className={`${styles.state} ${styles[survey.state]}`}>
                      {survey.state}
                    </span>
                  </div>

                  <div
                    className={styles.surveyCardDetails}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className={styles.surveyInfo}>
                      <p>Project: {survey.projectId}</p>
                      <p>
                        Created:{" "}
                        {new Date(survey.createdAt).toLocaleDateString()}
                      </p>
                      <p>Questions: {survey.questions.length}</p>
                    </div>

                    {survey.state === "active" && (
                      <div className={styles.surveyLink}>
                        <input
                          type="text"
                          value={getSurveyLink(survey)}
                          readOnly
                          className={styles.linkInput}
                        />
                        <button
                          onClick={() =>
                            navigator.clipboard.writeText(getSurveyLink(survey))
                          }
                          className={styles.copyButton}
                        >
                          Copy
                        </button>
                      </div>
                    )}

                    <div className={styles.cardActions}>
                      {getStateActions(survey)}
                      <button
                        onClick={() => viewResults(survey.surveyId)}
                        className={styles.viewButton}
                      >
                        View Results
                      </button>
                      {(survey.state === "draft" ||
                        survey.state === "active") && (
                        <button
                          onClick={() =>
                            navigate(`/surveys/${survey.surveyId}/edit`)
                          }
                          className={styles.editButton}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleAiSurveyGeneration = async () => {
    if (!aiPrompt.trim()) return;

    setIsGenerating(true);
    try {
      const response = await axios.post("/api/surveys/generate", {
        prompt: aiPrompt,
      });

      // Navigate to survey edit mode with the generated survey
      navigate(`/surveys/${response.data.surveyId}/edit`, {
        state: { survey: response.data },
      });
    } catch (err) {
      setError(
        "Failed to generate survey: " +
          (err.response?.data?.message || err.message)
      );
    } finally {
      setIsGenerating(false);
      setAiPrompt("");
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;

  return (
    <div className={styles.dashboard}>
      {error && (
        <div className={styles.errorContainer}>
          <div className={styles.error}>
            {error}
            <button onClick={dismissError} className={styles.dismissButton}>
              ×
            </button>
          </div>
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.headerTop}>
            <h2 className={styles.headerTitle}>My Surveys</h2>
            <button
              onClick={createNewSurvey}
              className={`${styles.createButton} ${
                activeSurveysCount >= SURVEY_LIMITS.MAX_ACTIVE_SURVEYS
                  ? styles.limitReached
                  : ""
              }`}
              title={
                activeSurveysCount >= SURVEY_LIMITS.MAX_ACTIVE_SURVEYS
                  ? `Maximum active surveys limit reached (${SURVEY_LIMITS.MAX_ACTIVE_SURVEYS})`
                  : "Create new survey"
              }
            >
              Create New Survey
            </button>
          </div>
          <div className={styles.surveyStats}>
            <div className={styles.surveyLimitContainer}>
              <span
                className={`${styles.activeCount} ${
                  activeSurveysCount >= SURVEY_LIMITS.MAX_ACTIVE_SURVEYS
                    ? styles.limitReached
                    : ""
                }`}
              >
                Active Surveys: {activeSurveysCount}/
                {SURVEY_LIMITS.MAX_ACTIVE_SURVEYS}
              </span>
              {activeSurveysCount >= SURVEY_LIMITS.MAX_ACTIVE_SURVEYS && (
                <div className={styles.limitWarning}>
                  <span className={styles.warningIcon}>⚠️</span>
                  Maximum limit of {SURVEY_LIMITS.MAX_ACTIVE_SURVEYS} active
                  surveys reached. Please pause or close active surveys before
                  launching new ones.
                </div>
              )}
            </div>
          </div>
          <div className={styles.aiPromptSection}>
            <div className={styles.aiPromptContainer}>
              <input
                type="text"
                value={aiPrompt}
                onChange={(e) => setAiPrompt(e.target.value)}
                placeholder="Describe your survey idea (e.g., 'Create a customer satisfaction survey for a coffee shop')"
                className={styles.aiPromptInput}
                disabled={isGenerating}
              />
              <button
                onClick={handleAiSurveyGeneration}
                className={`${styles.aiGenerateButton} ${
                  isGenerating ? styles.generating : ""
                }`}
                disabled={isGenerating || !aiPrompt.trim()}
              >
                {isGenerating ? (
                  <span className={styles.generatingText}>Generating...</span>
                ) : (
                  <>
                    <span className={styles.aiIcon}>✨</span>
                    Generate Survey
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.surveySections}>
        {renderSurveySection("Active Surveys", groupedSurveys.active, "active")}
        {renderSurveySection("Draft Surveys", groupedSurveys.drafts, "drafts")}
        {renderSurveySection("Paused Surveys", groupedSurveys.paused, "paused")}
        {renderSurveySection(
          "Other Surveys",
          groupedSurveys.others,
          "others",
          false
        )}
      </div>
    </div>
  );
};

export default Dashboard;
